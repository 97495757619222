<template>
  <footer class="bg-white">
    <div class="container mx-auto flex items-center justify-end px-4 py-4 text-center">
      <p class="text-xs text-black">Dit register wordt u aangeboden door</p>
      <a href="https://www.memori.nl" target="_blank"
        ><img class="ml-2 h-6" src="/assets/register/memori-logo.png" alt="Memori.nl"
      /></a>
    </div>
  </footer>
</template>
<script setup lang="ts"></script>
