<script lang="ts" setup>
import { FuneralCompany } from '~/entities/funeral-company'
import { FuneralCompanyResource } from '~/resources/funeral-company'

interface LayoutMeta {
  showMenu?: boolean
}

const route = useRoute()
const funeralCompanyResource = new FuneralCompanyResource()

interface Props {
  showMenu?: boolean
  showFooter?: boolean
  background_color?: string
}

const props = withDefaults(defineProps<Props>(), {
  showMenu: true,
  showFooter: false,
  background_color: '#f6f4f5'
})

const showMenu = computed(() => {
  return ((route.meta.layoutMeta as LayoutMeta)?.showMenu ?? props.showMenu)
})

const url = useRequestURL()
const subdomain = <string>url.hostname.split('.')[0]
const funeralCompanyResponse = await funeralCompanyResource.get(subdomain)
const funeralCompany = ref<FuneralCompany>(funeralCompanyResponse.data.value.item)
</script>

<template>
  <NavigationMenuUVW v-if="showMenu" :color="funeralCompany?.primary_color" />
  <main :style="{ backgroundColor: props.background_color }">
    <NuxtPage :funeralCompany="funeralCompany" />
  </main>
  <FooterUVW v-if="showFooter" />
</template>